import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js'
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings'
import { SectionDescription } from 'components/misc/Typography'
import { ReactComponent as TwitterIcon } from 'images/twitter-icon.svg'
import { ReactComponent as LinkedinIcon } from 'images/linkedin-icon.svg'
import { ReactComponent as GithubIcon } from 'images/github-icon.svg'
import profileImg from 'images/profileImg.jpeg'
import Tooltip from 'components/misc/Tooltip' // Import your Tooltip component

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const CardImage = styled.div`
  ${(props) => css`
    background-image: url('${props.imageSrc}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 30% 0;
  `}
  ${tw`w-80 h-96 rounded object-cover`}
  background-position: center 15%;
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  }
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
  heading = 'Meet Our Team.',
  subheading = 'The Person Behind Our Success',
  description = 'Our team is composed of talented and dedicated professionals who are passionate about what they do. Meet the person who makes it all happen.',
  cards = [
    {
      imageSrc: profileImg,
      position: 'Founder & Technical Lead',
      name: 'Manish Shaw',
      links: [
        {
          url: 'https://twitter.com',
          icon: TwitterIcon,
          showTooltip: true, // New prop to control tooltip visibility
          tooltipText: 'Coming Soon', // New prop for tooltip text
        },
        {
          url: 'https://linkedin.com',
          icon: LinkedinIcon,
          showTooltip: true, // New prop to control tooltip visibility
          tooltipText: 'Coming Soon', // New prop for tooltip text
        },
        {
          url: 'https://github.com',
          icon: GithubIcon,
          showTooltip: true, // New prop to control tooltip visibility
          tooltipText: 'Coming Soon', // New prop for tooltip text
        },
      ],
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a
                      key={linkIndex}
                      className="link"
                      href={link.url}
                      onClick={
                        link.showTooltip ? (e) => e.preventDefault() : null
                      } // Prevent default if tooltip is shown
                      aria-disabled={link.showTooltip ? 'true' : 'false'} // Set aria-disabled for accessibility
                    >
                      {link.showTooltip ? ( // Check if showTooltip prop exists
                        <Tooltip text={link.tooltipText}>
                          <link.icon className="icon" />
                        </Tooltip>
                      ) : (
                        <link.icon className="icon" />
                      )}
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  )
}
