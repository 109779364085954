import React from 'react'
import tw, { styled } from 'twin.macro'
import { css } from 'styled-components/macro' //eslint-disable-line
import Tooltip from 'components/misc/Tooltip' // Import the Tooltip component
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js'
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'
import { ReactComponent as BriefcaseIcon } from 'feather-icons/dist/icons/briefcase.svg'
import { ReactComponent as MoneyIcon } from 'feather-icons/dist/icons/dollar-sign.svg'
import TeamIllustrationSrc from 'images/team-illustration-2.svg'

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
])

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
])
const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Subheading = tw(SubheadingBase)`text-center md:text-left`
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`
const FeatureHeading = tw.div`font-bold text-lg text-primary-500`
const FeatureDescription = tw.div`mt-1 text-sm`

const PrimaryButton = styled(PrimaryButtonBase)`
  ${tw`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`}
  pointer-events: none; /* Disable the button */
  opacity: 0.5; /* Make it look disabled */
`

export default ({
  subheading = 'Our Expertise',
  heading = (
    <>
      We have the most <span tw="text-primary-500">innovative</span> technology
      solutions team.
    </>
  ),
  description = "At TechIT, we don't just provide services; we craft solutions tailored to your business needs. Whether it's custom software development, robust IT support, or cutting-edge cloud solutions, our team of experts brings unparalleled professionalism and deep industry knowledge to every project.",
  primaryButtonText = 'Discover How We Can Empower Your Business',
  primaryButtonUrl = '/services',
  tooltipText = 'Coming Soon', // New prop for tooltip text
  showTooltip = true, // New prop to control tooltip visibility
  features = null,
  textOnLeft = true,
}) => {
  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: 'Professionalism',
      description:
        'Our team consists of industry-certified experts who are dedicated to delivering top-notch solutions. With specialists across software development, IT consulting, cybersecurity, and more, we ensure your business is in the best hands.',
    },
    {
      Icon: MoneyIcon,
      title: 'Affordable',
      description:
        'We believe in providing value without compromising quality. Our pricing models are competitive, ensuring you receive the best rates for the highest quality services.',
    },
  ]

  if (!features) features = defaultFeatures

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={TeamIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIconContainer>
                    {<feature.Icon />}
                  </FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>
                      {feature.description}
                    </FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
            {showTooltip ? ( // Conditionally render the Tooltip
              <Tooltip text={tooltipText}>
                <PrimaryButton as="a" href={primaryButtonUrl}>
                  {primaryButtonText}
                </PrimaryButton>
              </Tooltip>
            ) : (
              <PrimaryButton as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </PrimaryButton>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}
