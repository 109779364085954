import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Button = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};

  &:hover {
    background-color: #3182ce;
  }
`

const ArrowUp = styled.span`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid white;
`

const ScrollToTopButton = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      const windowHeight = window.innerHeight
      const docHeight = document.documentElement.scrollHeight
      setShow(scrollTop > 300 || scrollTop + windowHeight >= docHeight)
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll() // Call once on mount to set initial state
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const scrollToTop = () => {
    const scrollStep = -window.scrollY / 15
    const step = () => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep)
        requestAnimationFrame(step)
      }
    }
    requestAnimationFrame(step)
  }

  return (
    <Button show={show} onClick={scrollToTop}>
      <ArrowUp />
    </Button>
  )
}

export default ScrollToTopButton
