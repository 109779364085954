import React, { useEffect, useState } from 'react' // Add useState
import { useLocation } from 'react-router-dom' // For better semantic linking
import tw from 'twin.macro'
import styled from 'styled-components'
import { Container as ContainerBase } from 'components/misc/Layouts.js'
import logo from '../../images/techitadLogo.jpg' // use our logo here instead

import SocialLinkWithTooltip from 'components/buttons/SocialLinkWithTooltip' // Adjust the import path as necessary

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`
const LogoImg = tw.img`w-8`
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row space-x-4`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`
export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-24 mr-3`}// Changed from w-28 to w-24
  }
`

const defaultLogoLink = (
  <LogoLink href="/">
    <img src={logo} alt="logo" className={tw`mr-2`} /> {/* Add margin-right */}
    <div className={tw`text-lg flex flex-col items-center`}>
      <div className={tw`block `}>TECHIT.LIVE</div>
      <div
        style={{ height: '1px', marginTop: '-12px', marginBottom: '-6px' }}
      ></div>
      <small
        className={tw`text-sm mt-1 opacity-75`}
        style={{ fontSize: '10px' }}
      >
        Empowering Digital Transformation
      </small>
    </div>
  </LogoLink>
)

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`

export default () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()) // State for current year
  const location = useLocation() // Get current location

  useEffect(() => {
    setCurrentYear(new Date().getFullYear()) // Set current year on mount
  }, [])

  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            {defaultLogoLink}
            {/* <LogoImg src={logo} />
            
            <LogoText>Treact</LogoText> */}
          </LogoContainer>
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link href="/components/innerPages/AboutUsPage">About Us</Link>
            <Link href={location.pathname === '/' ? '#ContactUs' : '/'}>
              Contact Us
            </Link>
            <Link
              href={location.pathname === '/' ? '#blogs' : '/'}
              className={tw`mx-4`}
            >
              Blog
            </Link>
            <Link
              href={location.pathname === '/' ? '#reviews' : '/'}
              className={tw`mx-3`}
            >
              Reviews
            </Link>
            {/* Ensure the target sections have the corresponding IDs in your main content */}
            {/* Example: <div id="ContactUs">...</div> */}
          </LinksContainer>
          <SocialLinkWithTooltip />
          <CopyrightText>
            &copy; Copyright {currentYear}, TechIT.live Inc. All Rights
            Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  )
}
