import React, { useState } from 'react'
import tw from 'twin.macro'
import { motion } from 'framer-motion'
import axios from 'axios'
import { ReactComponent as EmailNewsletterIconBase } from '../../images/email-newsletter-icon.svg'
import { Container as ContainerBase } from 'components/misc/Layouts.js'
import { SectionHeading } from 'components/misc/Headings.js'
import { PrimaryButton } from 'components/misc/Buttons.js'

const Container = tw(ContainerBase)`bg-secondary-800 -mx-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`
const FormColumn = tw.div`mt-12 lg:mt-0 lg:ml-16 w-full sm:w-auto`

const EmailNewsletterIcon = tw(
  EmailNewsletterIconBase
)`w-16 h-16 text-green-500`
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`
const Description = tw.p`text-gray-500 font-medium text-sm max-w-sm mt-2 sm:mt-1 text-center sm:text-left`

const Form = tw.form`text-sm max-w-sm sm:max-w-none mx-auto`
const Input = tw.input`w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-secondary-300 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200`
const Button = tw(
  PrimaryButton
)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-green-500 text-gray-100 hocus:bg-green-700 hocus:text-gray-300 border border-green-500 hocus:border-green-700 disabled:bg-gray-500 disabled:border-gray-500 disabled:cursor-not-allowed`

const SuccessMessage = tw(
  motion.div
)`bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-8`

const ErrorMessage = tw(
  motion.div
)`bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-8`

export default () => {
  const [email, setEmail] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isLoading, setIsLoading] = useState(false) // Add loading state

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!validateEmail(email)) {
      setIsValidEmail(false)
      setErrorMessage('Invalid email address')
      return
    }
    setIsValidEmail(true)
    setIsLoading(true) // Set loading to true before API call
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/newsletter/subscribe`,
        {
          email,
        }
      )
      if (response.status === 201) {
        setShowSuccessMessage(true)
        setErrorMessage('')
        setEmail('')
        setTimeout(() => setShowSuccessMessage(false), 3000) // Hide success message after 3 seconds
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          setErrorMessage('Email is already subscribed')
        } else {
          setErrorMessage('Error subscribing')
        }
      } else {
        setErrorMessage('Error subscribing')
      }
      setTimeout(() => setErrorMessage(''), 3000) // Hide error message after 3 seconds
    } finally {
      setIsLoading(false) // Set loading to false after API call
    }
  }

  return (
    <Container>
      <Content>
        <Row>
          <TextColumn>
            <EmailNewsletterIcon />
            <HeadingInfoContainer>
              <Heading>Stay Updated</Heading>
              <Description>
                Subscribe to our newsletter for the latest IT insights and
                updates.
              </Description>
            </HeadingInfoContainer>
          </TextColumn>
          <FormColumn>
            <Form onSubmit={handleSubmit}>
              <Input
                name="newsletter"
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setIsValidEmail(validateEmail(e.target.value))
                }}
              />
              <Button type="submit" disabled={!isValidEmail || errorMessage || isLoading}>
                {isLoading?  'Loading...'  : 'Subscribe'} {/* Show loading icon or button text */}
              </Button>
            </Form>
            {showSuccessMessage && (
              <SuccessMessage
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Subscribed successfully!
              </SuccessMessage>
            )}
            {errorMessage && (
              <ErrorMessage
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {errorMessage}
              </ErrorMessage>
            )}
          </FormColumn>
        </Row>
      </Content>
    </Container>
  )}