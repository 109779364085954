import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import Tooltip from 'components/misc/Tooltip' // Adjust the import path as necessary
// Adjust the import path as necessary
import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg'
import { ReactComponent as TwitterIcon } from '../../images/twitter-icon.svg'
import { ReactComponent as YoutubeIcon } from '../../images/youtube-icon.svg'

const SocialLinksContainer = tw.div`mt-10`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`

const SocialLinkWithTooltip = () => (
  <Tooltip text="Coming Soon">
    <SocialLinksContainer>
      <SocialLink
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.preventDefault()}
        disabled
      >
        <FacebookIcon />
      </SocialLink>
      <SocialLink
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.preventDefault()}
        disabled
      >
        <TwitterIcon />
      </SocialLink>
      <SocialLink
        href="#"
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.preventDefault()}
        disabled
      >
        <YoutubeIcon />
      </SocialLink>
    </SocialLinksContainer>
  </Tooltip>
)

export default SocialLinkWithTooltip
