import React, { Fragment, useState, useEffect } from 'react';
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js'
import { SubmitButton } from 'components/misc/Buttons.js'
import EmailIllustrationSrc from 'images/email-illustration.svg';
import axios from 'axios'
import { motion } from 'framer-motion'
import countryCodes from 'utils/countryCodes'; // Import the countryCodes object

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
])

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
])
const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Subheading = tw(SubheadingBase)`text-center md:text-left`
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Select = styled.select`
  ${tw`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 appearance-none bg-transparent pr-6`}
  
  &::placeholder {
    color: gray;
  }
`;


const Textarea = styled(Input).attrs({ as: 'textarea' })`
  ${tw`h-24`}
`

const SuccessMessage = tw(
  motion.div
)`bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-8`


export default ({
  subheading = 'Get in Touch',
  heading = (
    <>
      Reach out to <span tw="text-primary-500">us</span>
      <wbr /> today.
    </>
  ),
  description = 'We value your feedback and look forward to hearing from you.',
  submitButtonText = 'Submit',
  formAction = '#',
  formMethod = 'post',
  textOnLeft = true,
}) => {
  const [phoneError, setPhoneError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [nameError, setNameError] = useState('')
  const [subjectError, setSubjectError] = useState('')
  const [messageError, setMessageError] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [apiError, setApiError] = useState('')
  const [isLoading, setIsLoading] = useState(false) // Add loading state
  const [countryCode, setCountryCode] = useState({ code: 'IN',name:'India',callingCode: '+91' }); // Default to India's calling code


  useEffect(() => {
    const fetchCountryCode = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await axios.get(`https://api.ipapi.com/api/geo?lat=${latitude}&lon=${longitude}&access_key=YOUR_ACCESS_KEY`);
            const country = response.data.country_code; // Get country code from response
            const countryData = countryCodes.find(c => c.callingCode === country); // Match with countryCodes
            if (countryData) {
                setCountryCode({ code: countryData.code, name: countryData.name, callingCode: countryData.callingCode }); // Set the country code if found
            } else {
                setCountryCode({ code: 'IN', name: 'India', callingCode: '+91' }); // Default to India if not found
            }
          } catch (error) {
            console.error('Error fetching country code:', error);
            setCountryCode({ code: 'IN', name: 'India', callingCode: '+91' }); // Default to India if not found
          }
        });
      } else {
        setCountryCode({ code: 'IN', name: 'India', callingCode: '+91' }); // Default to India if not found
      }
    };

    fetchCountryCode();
  }, []); // Run once on component mount

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validatePhone = (phone) => /^(?:\+?\d{1,3})?[-.\s]?(\(?\d{1,4}?\)?[-.\s]?)?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(phone);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const rawPhoneNumber = formData.get('phoneNumber').replace(/\D/g, '');

    const formProps = { 
      ...Object.fromEntries(formData), 
      countryCode: countryCode.callingCode, 
      countryName: countryCode.name, 
      phoneNumber: rawPhoneNumber 
    };

    const errors = {
      email: !validateEmail(formProps.email) ? 'Please enter a valid email address' : '',
      name: !formProps.name ? 'Please enter your name' : '',
      subject: !formProps.subject ? 'Please enter a subject' : '',
      message: !formProps.message ? 'Please enter a message' : '',
      phone: formProps.phoneNumber && !validatePhone(formProps.phoneNumber) ? 'Please enter a valid phone number' : '',
      country: !countryCodes.find(country => country.callingCode === countryCode.callingCode) ? 'Please select a valid country code' : ''
    };

    setEmailError(errors.email);
    setNameError(errors.name);
    setSubjectError(errors.subject);
    setMessageError(errors.message);
    setPhoneError(errors.phone);
    setApiError(errors.country);

    if (Object.values(errors).some(error => error)) return;

    setIsLoading(true);
    try {
      setShowSuccessMessage(false);
      setApiError('');
      await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, formProps);
      setShowSuccessMessage(true);
    } catch (error) {
      setApiError(error.response?.data?.error || 'Error submitting form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container id="ContactUs">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit} noValidate>

              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                required
                onChange={(e) => {
                  if (!validateEmail(e.target.value)) {
                    setEmailError('Please enter a valid email address')
                  } else {
                    setEmailError('')
                  }
                }}
              />
              {emailError && <p tw="text-red-500 text-sm mt-1">{emailError}</p>}
              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                required
                onChange={(e) => {
                  if (!e.target.value) {
                    setNameError('Please enter your name')
                  } else {
                    setNameError('')
                  }
                }}
              />
              {nameError && <p tw="text-red-500 text-sm mt-1">{nameError}</p>}
              <Input
                type="text"
                name="subject"
                placeholder="Subject"
                required
                onChange={(e) => {
                  if (!e.target.value) {
                    setSubjectError('Please enter a subject')
                  } else {
                    setSubjectError('')
                  }
                }}
              />
              {subjectError && (
                <p tw="text-red-500 text-sm mt-1">{subjectError}</p>
              )}
              <div tw="flex items-center mt-4">
                <Select
                  name="countryCode"
                  value={`${countryCode.code}-${countryCode.callingCode}`}
                  onChange={(e) => {
                    const [selectedCode, selectedCallingCode] = e.target.value.split("-");
                    setCountryCode({
                      code: selectedCode,
                      name:`${countryCodes.find(country => country.code === selectedCode).name}`,
                      callingCode: selectedCallingCode,
                    });
                  }}
                  tw="mr-2 mt-10 w-24 "
                >
                  {countryCodes.map((country) => (
                    <option key={country.code} value={`${country.code}-${country.callingCode}`}>
                      {country.name} ({country.callingCode})
                    </option>
                  ))}
                </Select>

                <Input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  tw="-mt-0  flex-grow"
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/\D/g, '');
                    console.log({rawValue})
                    const isValidPhone = validatePhone(rawValue);
                    setPhoneError(isValidPhone ? '' : 'Please enter a valid phone number');
                  }}
                />
              </div>
              {phoneError && <p tw="text-red-500 text-sm mt-1">{phoneError}</p>}

              <Textarea
                name="message"
                placeholder="Your Message Here"
                required
                onChange={(e) => {
                  if (!e.target.value) {
                    setMessageError('Please enter a message')
                  } else {
                    setMessageError('')
                  }
                }}
              />
              {messageError && (
                <p tw="text-red-500 text-sm mt-1">{messageError}</p>
              )}
              {apiError && <p tw="text-red-500 text-sm mt-1">{apiError}</p>}
              <SubmitButton
                type="submit"
                disabled={
                  emailError ||
                  nameError ||
                  subjectError ||
                  phoneError ||
                  messageError ||
                  isLoading // Disable button when loading
                }
              >
                {isLoading ? 'Submitting...' : submitButtonText}{' '}
                {/* Show loading text */}
              </SubmitButton>
            </Form>
            {showSuccessMessage && (
              <SuccessMessage
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Thanks for reaching out, we will get back to you soon!
              </SuccessMessage>
            )}
            <a href="mailto:contact@techit.live" tw="mt-4 text-primary-500 underline block text-left">
                Having issues? Contact us at contact@techit.live
              </a>
              <p tw="mt-2 text-primary-500 block text-left">or call us at +91 6362376189</p>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}
