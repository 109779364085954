import React from 'react'
import tw from 'twin.macro'
import { SectionHeading } from 'components/misc/Headings.js'
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'
import Header from 'components/headers/light.js'
import Footer from 'components/footers/MiniCenteredFooter.js'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'

const Container = tw.div`min-h-screen bg-gray-100 text-gray-900 flex flex-col justify-between`
const Content = tw.div`max-w-screen-lg m-8 p-8 bg-white shadow-lg rounded-lg`
const Heading = tw(SectionHeading)`text-4xl text-center`
const Description = tw.p`mt-4 text-lg leading-relaxed text-center`
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 text-sm inline-block mx-auto`

const LearnMorePage = () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Content>
        <Heading>Learn More About Our Services</Heading>
        <Description>
          We offer a wide range of IT solutions tailored to meet the unique
          needs of your business. Our team of experts is dedicated to providing
          innovative and efficient solutions that drive growth and success.
          Whether you're a startup or an established enterprise, we have the
          expertise to help you achieve your goals.
        </Description>
        <PrimaryButton as="a" href="https://timerse.com/contact">
          Contact Us
        </PrimaryButton>
      </Content>
      <Footer />
    </AnimationRevealPage>
  )
}

export default LearnMorePage
