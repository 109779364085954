import React, { useState } from 'react'
import { motion } from 'framer-motion'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js'
import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg'
import { ReactComponent as MinusIcon } from 'feather-icons/dist/icons/minus.svg'

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`

const TwoColumn = tw.div`flex`
const Column = tw.div``

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`,
])

const FAQContent = tw.div`lg:ml-12`
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`
const Heading = tw(SectionHeading)`lg:text-left`
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`

const FAQSContainer = tw.dl`mt-12`
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`
const Question = tw.dt`flex justify-between items-center`
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
)

export default ({
  subheading = 'FAQs',
  heading = 'Questions',
  description = "Here are some frequently asked questions about our IT services and solutions. If you have any other questions, please don't hesitate to contact us using the form below.",
  imageSrc = 'https://images.unsplash.com/photo-1581092918056-0c4c3acd3789?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
  imageContain = false,
  imageShadow = true,
  faqs = null,
}) => {
  const defaultFaqs = [
    {
      question: 'What IT services do you offer?',
      answer:
        'We offer a wide range of IT services including custom software development, cloud solutions, cybersecurity, data analytics, IT consulting, and managed IT services. Our team of experts can tailor solutions to meet your specific business needs.',
    },
    {
      question: 'How can your IT solutions benefit my business?',
      answer:
        'Our IT solutions can help streamline your operations, increase efficiency, enhance security, and drive growth. By leveraging cutting-edge technologies and industry best practices, we enable businesses to stay competitive in the digital landscape and achieve their strategic goals.',
    },
    {
      question: 'Do you provide support after implementation?',
      answer:
        'Yes, we offer comprehensive post-implementation support. Our dedicated support team is available to address any issues, provide updates, and ensure the smooth operation of your IT systems. We also offer training to help your team make the most of the implemented solutions.',
    },
    {
      question: 'How do you ensure the security of our data?',
      answer:
        'We take data security very seriously. We implement robust security measures including encryption, firewalls, regular security audits, and compliance with industry standards. Our team stays up-to-date with the latest security threats and best practices to ensure your data remains protected.',
    },
    {
      question: 'Can you work with our existing IT infrastructure?',
      answer:
        'Absolutely. We have experience integrating our solutions with various existing IT infrastructures. Our team will assess your current setup and design solutions that seamlessly integrate with your existing systems, ensuring minimal disruption to your operations.',
    },
  ]

  if (!faqs || faqs.length === 0) faqs = defaultFaqs

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null)

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null)
    else setActiveQuestionIndex(questionIndex)
  }

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image
              imageContain={imageContain}
              imageShadow={imageShadow}
              imageSrc={imageSrc}
            />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index)
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? (
                          <MinusIcon />
                        ) : (
                          <PlusIcon />
                        )}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: 'auto', marginTop: '16px' },
                        collapsed: { opacity: 0, height: 0, marginTop: '0px' },
                      }}
                      initial="collapsed"
                      animate={
                        activeQuestionIndex === index ? 'open' : 'collapsed'
                      }
                      transition={{
                        duration: 0.3,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  )
}
