import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container } from 'components/misc/Layouts'
import tw from 'twin.macro'
import Header from 'components/headers/light.js'
import Footer from 'components/footers/FiveColumnWithInputForm.js'
import { SectionHeading } from 'components/misc/Headings'
import GridWithFeaturedPost from 'components/blogs/GridWithFeaturedPost.js'

const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-gray-900`

export default ({}) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <GridWithFeaturedPost />
      </Container>
      <Footer />
    </AnimationRevealPage>
  )
}

const getPlaceholderPost = () => ({
  imageSrc:
    'https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80',
  category: 'Travel Guide',
  date: 'April 19, 2020',
  title: 'Visit the beautiful Alps in Switzerland',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  url: 'https://reddit.com',
})
