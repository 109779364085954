import React from 'react'
import tw, { styled } from 'twin.macro'

const TooltipContainer = styled.div`
  ${tw`invisible bg-black text-white text-center rounded py-1 px-2 absolute z-10 opacity-0 transition-opacity duration-300`}
  bottom: 70%; /* Reduce the gap between the tooltip and the element */
  left: 50%;
  transform: translateX(-50%);

  &::after {
    content: '';
    ${tw`absolute left-1/2 transform -translate-x-1/2 border-4 border-solid border-transparent`}
    top: 100%; /* Position the arrow at the bottom of the tooltip */
    border-top-color: black; /* Arrow color */
  }
`

const TooltipWrapper = styled.div`
  ${tw`relative inline-block`}

  &:hover ${TooltipContainer} {
    ${tw`visible opacity-100`}
  }
`

const Tooltip = ({ children, text }) => {
  const isComingSoon = text === 'Coming Soon'

  return (
    <TooltipWrapper>
      {React.Children.map(children, (child) => {
        if (
          React.isValidElement(child) &&
          (child.type === 'a' || child.type === 'button')
        ) {
          return React.cloneElement(child, {
            disabled: child.type === 'button' ? isComingSoon : undefined, // Disable button if 'Coming Soon'
            className: isComingSoon ? 'disabled-link' : '', // Add class for disabled styling
            onClick:
              isComingSoon && child.type === 'a'
                ? (e) => e.preventDefault() // Prevent link navigation
                : undefined,
          })
        }
        return child
      })}
      {isComingSoon && <TooltipContainer>{text}</TooltipContainer>}
    </TooltipWrapper>
  )
}

export default Tooltip
