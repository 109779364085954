import React from 'react'
import tw, { styled } from 'twin.macro'
import { css } from 'styled-components/macro' //eslint-disable-line
import Tooltip from 'components/misc/Tooltip' // Import the Tooltip component
import { SectionHeading, Subheading } from 'components/misc/Headings.js'
import { PrimaryLink as PrimaryLinkBase } from 'components/misc/Links.js'
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'
import { ReactComponent as LocationIcon } from 'feather-icons/dist/icons/map-pin.svg'
import { ReactComponent as TimeIcon } from 'feather-icons/dist/icons/clock.svg'
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right-icon.svg'

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const ThreeColumn = tw.div`flex flex-wrap justify-center`
const Column = tw.div`w-full max-w-sm md:w-1/2 lg:w-1/3 px-4 mb-8`

const HeadingColumn = tw.div`w-full max-w-lg mx-auto text-center mb-12`
const HeadingInfoContainer = tw.div``
const HeadingTitle = tw(SectionHeading)`mt-4`
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
  pointer-events: none; /* Disable the button */
  opacity: 0.5; /* Make it look disabled */
`

const Card = tw.div`bg-white rounded-lg shadow-md overflow-hidden`
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-64 bg-cover bg-center`,
])

const CardText = tw.div`p-6`

const CardHeader = tw.div`flex justify-between items-center`
const CardCompany = tw.div`text-primary-500 font-bold text-lg`
const CardType = tw.div`font-semibold text-sm text-gray-600`

const CardTitle = tw.h5`text-xl font-bold mt-4`

const CardMeta = tw.div`flex items-center mt-4 text-gray-600 text-sm`

const CardMetaFeature = styled.div`
  ${tw`flex items-center mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`
const CardAction = styled(PrimaryButtonBase)`
  ${tw`w-full mt-6 relative inline-block`}
  pointer-events: none; /* Disable the button */
  opacity: 0.5; /* Make it look disabled */
`

export default ({
  subheading = 'Our Portfolio',
  headingHtmlComponent = (
    <>
      Innovative <span tw="text-primary-500">IT Solutions</span> for Modern
      Businesses
    </>
  ),
  description = 'Explore our diverse portfolio of cutting-edge IT projects that have transformed businesses across various industries. From custom software development to cloud solutions and cybersecurity, we deliver results that drive growth and efficiency.',
  linkText = 'View All Projects',
  cardLinkText = 'View Project Details',
}) => {
  const cards = [
    {
      imageSrc:
        'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80',
      company: 'TechCorp Inc.',
      type: 'Custom Software Development',
      title: 'Enterprise Resource Planning (ERP) System',
      durationText: '6 Months Development',
      locationText: 'On-Premise & Cloud',
    },
    {
      imageSrc:
        'https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80',
      company: 'DataSafe Solutions',
      type: 'Cybersecurity',
      title: 'Advanced Threat Detection System',
      durationText: '3 Months Implementation',
      locationText: 'Cloud-based',
    },
    {
      imageSrc:
        'https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80',
      company: 'HealthTech Innovations',
      type: 'Mobile App Development',
      title: 'Telemedicine Platform',
      durationText: '4 Months Development',
      locationText: 'iOS & Android',
    },
  ]
  return (
    <Container>
      <Content>
        <HeadingColumn>
          <HeadingInfoContainer>
            <Subheading>{subheading}</Subheading>
            <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
            <HeadingDescription>{description}</HeadingDescription>
            <Tooltip text="Coming Soon">
              <PrimaryLink>
                {linkText} <ArrowRightIcon />
              </PrimaryLink>
            </Tooltip>{' '}
            {/* Wrap PrimaryLink with Tooltip */}
          </HeadingInfoContainer>
        </HeadingColumn>
        <ThreeColumn>
          {cards.map((card, index) => (
            <Column key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardCompany>{card.company}</CardCompany>
                    <CardType>{card.type}</CardType>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  <CardMeta>
                    <CardMetaFeature>
                      <TimeIcon /> {card.durationText}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <LocationIcon /> {card.locationText}
                    </CardMetaFeature>
                  </CardMeta>
                  <Tooltip text="Coming Soon">
                    <CardAction>{cardLinkText}</CardAction>
                  </Tooltip>{' '}
                  {/* Wrap CardAction with Tooltip */}
                  {/* Wrap CardAction with Tooltip */}
                </CardText>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  )
}
